
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { GridFirst, GridSecond, GridThird } from "./EditToolsAddText";

import {
  resetCurrentTargetMenu,
  setCurrentTargetMenu,
  setMenuList,
  setSelectedMenuGroup,
  setShowAllMenu,
  setShowEditMenu,
} from "../../store/menuSlice";
import {
  addStudioElement,
  updateStudioElements,
} from "../../store/studioSlice";
import { getMenuRowsWithLayout, getMulitLineTextHeight, getTextHeight } from "../../utils";
import { Loyalty } from "@mui/icons-material";
import { getChilds } from "../Studio/StudioMenuElement";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
    overflow: "auto",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    width: "80rem",
    maxWidth: "82rem", // Increase width to medium size
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoCloseCircleOutline />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



const EditToolsContentTextMenuElements = (props) => {
  const { menu, studio } = useSelector((state) => state);
  console.log("touseeq studio : ",studio);
  const { studioElements } = studio?.present;
  const {
    showAllMenu,
    menuList,
    showEditMenu,
    currentTargetMenu,
    selectedMenuGroup,
  } = menu;
  const dispatch = useDispatch();
  const [layout, setLayout] = React.useState([]);
  const changeMenuList = (paylaod) => {
    dispatch(setMenuList(paylaod));
  };
  const changeTargetMenu = (payload) => {
    dispatch(setCurrentTargetMenu(payload));
  };
  const handleClickOpen = () => {
    // setOpen(true);
    dispatch(setShowAllMenu(true));
    dispatch(resetCurrentTargetMenu());
    dispatch(setSelectedMenuGroup(null));
  };

  const handleClose = (isClose) => {
    dispatch(setShowAllMenu(false));
    dispatch(setShowEditMenu(false));

    const selectedItems = [...menuList]?.filter((x) => x?.selected);
    let temp = selectedItems[0].id
    let widthArray = [];
    const stylesRow = [];
    getMenuRowsWithLayout(selectedItems, layout?.id, studioElements)?.map((item) => {
      const width = item.elements[0].props?.width;
      stylesRow.push(item);
      if (width) {
        widthArray.push(width);
      }
      return width;
    });
    const maxLayerWidth = Math.max(...widthArray);

    let accumulatedTextHeight = 0;
    const rowItems = [];
    const rows = getMenuRowsWithLayout(selectedItems, layout?.id, studioElements)?.map(
      (ele, index) => {

        let layoutX;
        let layoutY;
        let layoutW;

        if (layout?.id === 1 || layout?.id === 5 || layout?.id === 6 || layout?.id === 12 || layout?.id === 13) {
          const textHeight = getTextHeight(ele.elements[0]?.props?.text, `italic ${ele.elements[0].props.fontSize}px Arial`);

          // Layout 1
          if (layout?.id === 1) {
            layoutX = ele.elements[0].props.dataType === 'icon' ? maxLayerWidth / 2 - 5 : 0;


            if (ele.elements[0]?.props?.dataType === 'title') {

              if (ele.elements[0]?.props?.menuID?.id !== temp) {
                layoutY = rowItems[index - 1]?.elements[0]?.props?.y + 30;
              }
            }
            if (ele.elements[0]?.props?.dataType === 'description') {
              const desY = rowItems[index - 1].elements[0]?.props?.y;
              const desX = rowItems[index - 1].elements[0]?.props?.x;
              const desW = rowItems[index - 1].elements[0]?.props?.width;
              const desT = rowItems[index - 1].elements[0]?.props?.text;
              const desF = rowItems[index - 1].elements[0]?.props?.fontSize;

              const textDesMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              })
              layoutY = textDesMatrics.y + textDesMatrics.height;
            }
            if (ele.elements[0]?.props?.dataType === 'price') {
              const desY = rowItems[index - 1].elements[0]?.props?.y;
              const desX = rowItems[index - 1].elements[0]?.props?.x;
              const desW = rowItems[index - 1].elements[0]?.props?.width;
              const desT = rowItems[index - 1].elements[0]?.props?.text;
              const desF = rowItems[index - 1].elements[0]?.props?.fontSize;
              const textDesMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              })
              layoutY = textDesMatrics.y + 25;

            }
            if (ele.elements[0]?.props?.dataType === 'icon') {
              const desY = rowItems[index - 1].elements[0]?.props?.y;
              const desX = rowItems[index - 1].elements[0]?.props?.x;
              const desW = rowItems[index - 1].elements[0]?.props?.width;
              const desT = rowItems[index - 1].elements[0]?.props?.text;
              const desF = rowItems[index - 1].elements[0]?.props?.fontSize;
              const textPriceMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              })
              layoutY = rowItems[index - 1].elements[0]?.props?.y + textPriceMatrics.height;
            }
            if (ele.elements[0]?.props?.dataType === 'addOns') {
              const desY = rowItems[index - 1].elements[0]?.props?.y;
              const desX = rowItems[index - 1].elements[0]?.props?.x;
              const desW = rowItems[index - 1].elements[0]?.props?.width;
              const desT = rowItems[index - 1].elements[0]?.props?.text;
              const desF = rowItems[index - 1].elements[0]?.props?.fontSize;
              const textPriceMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              })
              layoutY = rowItems[index - 1].elements[0]?.props?.y + textPriceMatrics.height;
            }
          }

          // Layout 2
          if (layout?.id === 12 && (ele.elements[0]?.props?.dataType === 'title' || ele.elements[0]?.props?.dataType === 'description' || ele.elements[0]?.props?.dataType === 'price')) {
            layoutY = index > 0 ? accumulatedTextHeight : accumulatedTextHeight;
            layoutW = ele.elements[0]?.props?.width
          }

          if (layout?.id === 12 && ele.elements[0]?.props?.type === 'icon') {
            const iconY = rowItems[index - 3];
            layoutY = iconY.elements[0].props.y;
          }

          //layout 3
          if (layout?.id === 13 && ele.elements[0]?.props?.type === 'icon') {
            const iconY = rowItems[index - 2];
            if (ele.elements[0]?.props?.menuID?.id !== temp) {
              layoutY = iconY.elements[0].props.y;
            }
          }
          if (layout?.id === 13 && (ele.elements[0]?.props?.dataType === 'title' || ele.elements[1]?.props?.dataType === 'price')) {
            layoutY = index > 0 ? accumulatedTextHeight * 0.6 : accumulatedTextHeight;
            layoutW = ele.elements[0]?.props?.width
          }
          //layout 5 
          if (layout?.id === 5) {
            if (ele.elements[0]?.props?.dataType === 'title') {
              if (ele.elements[0]?.props?.menuID?.id !== temp) {
                const titleY = layout?.id === 2 ? 1 : 3;
                layoutY = rowItems[index - titleY]?.elements[0]?.props?.y + 80;
              }
            }
            if (ele.elements[0]?.props?.dataType === 'description') {
              const desY = rowItems[index - 1].elements[0]?.props?.y;
              const desX = rowItems[index - 1].elements[0]?.props?.x;
              const desW = rowItems[index - 1].elements[0]?.props?.width;
              const desT = rowItems[index - 1].elements[0]?.props?.text;
              const desF = rowItems[index - 1].elements[0]?.props?.fontSize;

              const textDesMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              });
              layoutY = textDesMatrics.y + textDesMatrics.height;
              layoutW = ele.elements[0]?.props?.width + 10;
            }
            if (ele.elements[0]?.props?.dataType === 'price') {
              const priceY = layout?.id === 5 ? 1 : 2;

              const desY = rowItems[index - priceY].elements[0]?.props?.y;
              const desX = rowItems[index - priceY].elements[0]?.props?.x;
              const desW = rowItems[index - priceY].elements[0]?.props?.width;
              const desT = rowItems[index - priceY].elements[0]?.props?.text;
              const desF = rowItems[index - priceY].elements[0]?.props?.fontSize;
              const textDesMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              });

              if (layout?.id === 5) {
                layoutY = textDesMatrics.y + 30;
              } else {
                layoutY = textDesMatrics.y + 5;
              }
            }

            if (ele.elements[0]?.props?.type === 'icon') {
              const iconY = rowItems[index - 3];
              layoutY = ele.elements[1]?.props.menuID.id === 1 ? 0 : iconY.elements[0].props.y - 40;
            }

            if (ele.elements[0]?.props?.dataType === 'addOns') {
              const desY = rowItems[index - 1].elements[0]?.props?.y;
              const desX = rowItems[index - 1].elements[0]?.props?.x;
              const desW = rowItems[index - 1].elements[0]?.props?.width;
              const desT = rowItems[index - 1].elements[0]?.props?.text;
              const desF = rowItems[index - 1].elements[0]?.props?.fontSize;

              const textDesMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              });

              layoutY = textDesMatrics.y + textDesMatrics.height + 20;
              layoutW = ele.elements[0]?.props?.width + 10;
            }
          }
          // layout 6
          if (layout?.id === 6) {
            if (ele.elements[0]?.props?.dataType === 'title') {
              if (ele.elements[0]?.props?.menuID?.id !== temp) {
                const titleY = layout?.id === 2 ? 1 : 3;
                layoutY = rowItems[index - titleY]?.elements[0]?.props?.y + 100;
              }
            }
            if (ele.elements[0]?.props?.dataType === 'description') {
              const desY = rowItems[index - 1].elements[0]?.props?.y;
              const desX = rowItems[index - 1].elements[0]?.props?.x;
              const desW = rowItems[index - 1].elements[0]?.props?.width;
              const desT = rowItems[index - 1].elements[0]?.props?.text;
              const desF = rowItems[index - 1].elements[0]?.props?.fontSize;

              const textDesMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              });
              layoutY = textDesMatrics.y + textDesMatrics.height;
              layoutW = ele.elements[0]?.props?.width + 10;
            }
            if (ele.elements[0]?.props?.dataType === 'price') {
              const priceY = layout?.id === 5 ? 1 : 2;

              const desY = rowItems[index - priceY].elements[0]?.props?.y;
              const desX = rowItems[index - priceY].elements[0]?.props?.x;
              const desW = rowItems[index - priceY].elements[0]?.props?.width;
              const desT = rowItems[index - priceY].elements[0]?.props?.text;
              const desF = rowItems[index - priceY].elements[0]?.props?.fontSize;
              const textDesMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              });

              if (layout?.id === 5) {
                layoutY = textDesMatrics.y + 10;
              } else {
                layoutY = textDesMatrics.y + 5;
              }
            }

            if (ele.elements[0]?.props?.type === 'icon') {
              const iconY = rowItems[index - 3];
              layoutY = ele.elements[1]?.props.menuID.id === 1 ? 0 : iconY.elements[0].props.y - 40;
            }

            if (ele.elements[0]?.props?.dataType === 'addOns') {
              const desY = rowItems[index - 1].elements[0]?.props?.y;
              const desX = rowItems[index - 1].elements[0]?.props?.x;
              const desW = rowItems[index - 1].elements[0]?.props?.width;
              const desT = rowItems[index - 1].elements[0]?.props?.text;
              const desF = rowItems[index - 1].elements[0]?.props?.fontSize;

              const textDesMatrics = getMulitLineTextHeight({
                text: desT,
                font: desF,
                width: desW,
                x: desX,
                y: desY
              });

              layoutY = textDesMatrics.y + textDesMatrics.height + 40;
              layoutW = ele.elements[0]?.props?.width + 10;
            }
          }
          accumulatedTextHeight += textHeight ? textHeight : 0;
        }
        //layout4
        if (layout?.id === 4) {
          layoutW = ele.elements[0]?.props?.width
        }


        rowItems.push({
          elements: [
            {
              ...ele.elements[0],
              props: {
                ...ele.elements[0].props,
                ...(layoutW !== undefined ? { width: layoutW } : {}),
                ...(layoutY !== undefined ? { y: layoutY } : {}),
                ...(layoutX !== undefined ? { x: layoutX } : {}),
              },
            },
          ],
        })
        return {
          elements: [
            {
              ...ele.elements[0],
              props: {
                ...ele.elements[0].props,
                width: maxLayerWidth,
                ...(layoutW !== undefined ? { width: layoutW } : { width: maxLayerWidth }),
                ...(layoutY !== undefined ? { y: layoutY } : {}),
                ...(layoutX !== undefined ? { x: layoutX } : {}),
              },
            },
          ],
        };
      }
    );

    if (!selectedMenuGroup) {
      dispatch(
        addStudioElement({
          id: `menu-element-${uuid()}`,
          type: "menu",
          rows: rows,
          layoutId: layout?.id,
          fontSize: 15,
          draggable: true,
          textDecoration: "",
          visible: true,
          fontFamily: "Arial",
          fontStyle: "normal",
          strokeWidth: 0,
          opacity: 1,
          scalx: 1,
          scaly: 1,
        })
      );
    } else {
      const newElements = studioElements?.filter(
        (ele) => ele?.id != selectedMenuGroup?.id
      );
      newElements?.push({
        id: `menu-element-${uuid()}`,
        type: "menu",
        rows: rows,
        fontSize: 15,
        fontFamily: "Arial",
        fontStyle: "normal",
        strokeWidth: 0,
        opacity: 1,
        layoutId: layout?.id,
        draggable: true,
        visible: true,
        scalex: 1,
        scaley: 1,

      });
      dispatch(updateStudioElements(newElements));
      dispatch(setSelectedMenuGroup(null));
    }
    dispatch(resetCurrentTargetMenu());
  };
  const handleCheckElement = () => {
    let copy = [...menuList];
    if (!currentTargetMenu?.id) {
      copy?.push({
        ...currentTargetMenu,
        id: copy?.length + 1,
        layout: layout,
      });
    } else {
      copy = copy?.map((t) =>
        t?.id === currentTargetMenu?.id
          ? { ...currentTargetMenu, layout: layout }
          : t
      );
    }
    dispatch(resetCurrentTargetMenu());
    changeMenuList(copy);
    dispatch(setShowEditMenu(false));
  };

  return (
    <>
      <Typography sx={{ color: "#fff" }} mt={3} mb={1.5}>
        Menu Elements
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Button
          sx={{ width: "100%" }}
          style={{
            backgroundColor: "#3fbce7",
          }}
          variant="contained"
          onClick={() => handleClickOpen()}
        >
          Add Menu
        </Button>
      </Box>
      <BootstrapDialog
        onClose={() => dispatch(setShowAllMenu(false))}
        aria-labelledby="customized-dialog-title"
        open={showAllMenu}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => dispatch(setShowAllMenu(false))}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
            Add Menu
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <GridFirst
              setLayout={setLayout}
              changeMenuList={changeMenuList}
              changeTargetMenu={changeTargetMenu}
            />
            <Divider orientation="vertical" vertical="true" flexItem />

            <GridSecond
              changeMenuList={changeMenuList}
              changeTargetMenu={changeTargetMenu}
            />
            <Divider orientation="vertical" vertical="true" flexItem />
            <GridThird
              changeMenuList={changeMenuList}
              changeTargetMenu={changeTargetMenu}
            />
          </Box>
        </DialogContent>
        {showEditMenu ? (
          <DialogActions
            sx={{
              display: " flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              width: "78%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0 17px 0 32px",
                width: "46%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  color: "red",
                  border: "1px solid #B8B8B8",
                  borderRadius: "4px",
                }}
              >
                <Button
                  onClick={() => {
                    const newMenuList = menuList?.filter(
                      (m) => m?.id !== currentTargetMenu?.id
                    );
                    dispatch(setMenuList(newMenuList));
                    dispatch(setShowEditMenu(false));
                  }}
                >
                  <DeleteForeverIcon sx={{ color: "gray" }} />
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    color: "red",
                    border: "1px solid #B8B8B8",
                    borderRadius: "4px",
                  }}
                >
                  <Button onClick={() => dispatch(setShowEditMenu(false))}>
                    <CloseIcon sx={{ color: "gray" }} />
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    color: "red",
                    border: "1px solid #B8B8B8",
                    borderRadius: "4px",
                  }}
                >
                  <Button onClick={(e) => handleCheckElement(e)}>
                    <DoneIcon sx={{ color: "gray" }} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogActions>
        ) : (
          <DialogActions>
            {selectedMenuGroup ? (
              <Button
                variant="contained"
                disabled={!menuList?.some((x) => x?.selected)}
                autoFocus
                onClick={handleClose}
              >
                Update Menu
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: "#3fbce7",
                }}
                disabled={!menuList?.some((x) => x?.selected)}
                variant="contained"
                autoFocus
                onClick={handleClose}
              >
                Add Menu
              </Button>
            )}
          </DialogActions>
        )}
      </BootstrapDialog>
    </>
  );
};

export default EditToolsContentTextMenuElements;